import { ekaReducer } from '@eka/redux/eakSlice';
import { configureStore } from '@reduxjs/toolkit';
import { assetsReducer } from '@screens/AssetOverview/redux/assetsSlice';
import { assetsTypeReducer } from '@screens/AssetType/redux/assetTypeSlice';
import { usersReducer } from '@screens/UserOverview/redux/usersSlice';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    eka: ekaReducer,
    assets: assetsReducer,
    assetsType: assetsTypeReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
