import { createSlice } from '@reduxjs/toolkit';

interface UsersState {}

const initialState: UsersState = {};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
});

export const usersActions = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
