import { ApolloClient, ApolloLink, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { Context } from '@components/AppInfoContext/AppInfoContext';
import { useAppInfo } from '@components/AppInfoContext/use-app-info';
import { useAuthenticatedFetch } from '@kc/app-init-workflow/react';
import { useSnackbar } from 'notistack';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import createApolloCache from './cache/apolloCache';
import RetryUnauthorizedRequestsLink from './links/RetryUnauthorizedRequestsLink/RetryUnauthorizedRequestsLink';
import { createUploadLink } from 'apollo-upload-client';

type Props = {
  children: ReactNode;
};

export function GraphqlProvider({ children }: Props) {
  const { t } = useTranslation('general', { useSuspense: false });
  const authenticatedFetch = useAuthenticatedFetch();
  const { context, refreshToken } = useAppInfo<Context>();
  const { enqueueSnackbar } = useSnackbar();
  const frontendGatewayUrl = context.services.ecockpitFrontendGateway;

  // un-memoized onError returns on every onError a new object reference triggering reload
  // the errors occure after new accessToken refresh (pending, no data response)

  const graphQLOnErrorLink = useMemo(() => {
    return onError(({ graphQLErrors }: ErrorResponse) => {
      graphQLErrors?.forEach((error) => {
        if (error.extensions?.classification === 'HTTP_FORBIDDEN') {
          enqueueSnackbar(t('general:Please_contact_your_administrator_for_permissions'), {
            variant: 'error',
          });
        }
      });
    });
  }, [enqueueSnackbar, t]);

  const client: ApolloClient<NormalizedCacheObject> = useMemo(
    () =>
      new ApolloClient({
        cache: createApolloCache(),
        link: ApolloLink.from([
          new RetryUnauthorizedRequestsLink(
            () =>
              new Promise<string>((resolve, reject) => {
                let value: string;
                refreshToken().subscribe({ next: (v) => (value = v), error: reject, complete: () => resolve(value) });
              })
          ),
          graphQLOnErrorLink,
          createUploadLink({
            uri: frontendGatewayUrl.replace(/\/$/, '') + '/graphql',
            fetch: authenticatedFetch,
          }),
        ]),
      }),

    [authenticatedFetch, frontendGatewayUrl, refreshToken, graphQLOnErrorLink]
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default GraphqlProvider;
